
import { defineComponent, ref } from "vue";
import api from "@/assets/js/api";
import { Collapse, CollapseItem, Cell } from "vant";

export default defineComponent({
  components: {
    [Collapse.name]: Collapse,
    [CollapseItem.name]: CollapseItem,
    [Cell.name]: Cell,
  },
  setup(props, ctx) {
    const list = ref();
    const activeName = ref();

    const getList = async () => {
      list.value = (await api.gethelpList()).data;
    };
    getList();
    return { list, activeName };
  },
});
